import React from 'react';
import Utils from '../../common/utils';
import Autosuggest from 'react-autosuggest';
import theme from '../styles/home.css'
import Network from '../../common/network'
import News from './newsComponents';
import { LinearProgress, Grid } from '@material-ui/core';


const SEARCH_SUGGESTIONS_BASE_URL = 'https://suggest.weatherport.co/autocomplete/autocomplete?count=10&kwd='
const LOG_PREFIX = '##Home'
const WEATHER_BASE_URL = "https://weatherport.co/hapi/getWeatherData"
let YAHOO_TARGET_UTL = ""
let SEARCH_COUNT_ANALYTICS_TARGET_URL = ""
const QUERY_MACRO = "%24search_query%24"


class Home extends React.Component {

    constructor(props) {
        super(props)
        this.lastQuery = ''
        this.state = {
            suggestions: [],
            newsItems: [],
            isSearchingForQuery: false,
            weatherData: [], isWeatherDataAvailable: false,
            shouldHideBrandImage: false,
            temperatures: []
        };
        this.initialiseWithUrlParams();
    }

    initialiseWithUrlParams() {
        YAHOO_TARGET_UTL = Utils.getValForKeyFromCurrentUrl("yahoo_target_url");
        SEARCH_COUNT_ANALYTICS_TARGET_URL = Utils.getValForKeyFromCurrentUrl("search_count_event_pixel");
        this.latitude = Utils.getValForKeyFromCurrentUrl("latitude");
        this.longitude = Utils.getValForKeyFromCurrentUrl("longitude");
        this.city = Utils.getValForKeyFromCurrentUrl("city");
        debugger
        this.hasSufficientDataToShowWeather = !(Utils.isNull(this.latitude) || Utils.isNull(this.longitude) || Utils.isNull(this.city));
    }

    componentDidMount() {
        if (!this.hasSufficientDataToShowWeather) {
            console.log(LOG_PREFIX + " one or more of weather data params are missing");
            return;
        }
        Network.fetchWithTimeout(WEATHER_BASE_URL + "?latitude=" + this.latitude + "&longitude=" + this.longitude)
            .then((response) => {
                if (false === response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((resp) => {
                let results = resp['currently']
                let hourly = resp['hourly'];
                if (Utils.isNull(hourly) === false && Utils.isNull(hourly['data']) === false) {
                    var minTemp = 2000;//Yeah some very high random value
                    var maxTemp = 0;
                    hourly['data'].forEach(element => {
                        var temp = element['temperature'];
                        if (temp < minTemp) {
                            minTemp = temp;
                        }

                        if (temp > maxTemp) {
                            maxTemp = temp;
                        }
                    });
                }


                if (Utils.isNull(results)) {
                    throw new Error("Did not get success as true")
                }

                var temperatures = []
                temperatures['minTemp'] = Math.round(minTemp);
                temperatures['maxTemp'] = Math.round(maxTemp);


                this.setState({ weatherData: results, isWeatherDataAvailable: true, temperatures: temperatures });
            })
            .catch((e) => {
                console.log(LOG_PREFIX, e);
            })
    }

    onChange = (event, { newValue }) => {
        if (Utils.isNull(newValue) || Utils.isEmptyStr(newValue)) {
            this.lastQuery = '';
            this.setState({
                shouldHideBrandImage: false
            })
        } else {
            this.lastQuery = newValue;
            this.setState({
                shouldHideBrandImage: true
            })
        }
    };

    onKeyDown = (event) => {
        console.log("####event pressed" + event.key + " " + event.metaKey);
        if (event.key === "Enter") {
            this.openSelectedSuggestion(this.lastQuery);
            this.lastQuery = null;
            this.setState({ suggestions: [], shouldHideBrandImage: true, isSearchingForQuery: false })
        }
    };

    makeRequestForQuery(query) {
        // Make a query only if the query-value is present
        if (false === Utils.isEmptyStr(query)) {
            this.fetchSuggestionsForQuery(query);
        }
    }

    fetchSuggestionsForQuery(query) {
        this.setState({
            isSearchingForQuery: true
        })

        Network.fetchWithTimeout(SEARCH_SUGGESTIONS_BASE_URL + query)
            .then((response) => {
                this.setState({
                    isSearchingForQuery: false
                })
                if (false === response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((resp) => {
                let results = resp[query]
                if (Utils.isNull(results)) {
                    throw new Error("Did not get success as true")
                }

                let formattedData = this.getSuggestionsFromResults(results)
                this.setState({ suggestions: formattedData });
            })
            .catch((e) => {
                this.setState({
                    isSearchingForQuery: false
                })
                console.log(LOG_PREFIX, e);
            })
    }

    getSuggestionsFromResults(results) {
        var suggestions = []
        results.forEach(element => {
            suggestions.push(element);
        })
        return suggestions;
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.lastQuery = value;
        this.makeRequestForQuery(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    getSuggestionValue = ({ suggestion }) => {
        return suggestion
    };

    onSuggestionSelected(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) {
        if (Utils.isNull(YAHOO_TARGET_UTL)) {
            console.log(LOG_PREFIX + " yahoo target url is not specified");
        }
        Network.fetchWithTimeout(SEARCH_COUNT_ANALYTICS_TARGET_URL.replace(QUERY_MACRO, suggestion));
        window.open(YAHOO_TARGET_UTL + suggestion, '_blank');
    }

    openSelectedSuggestion(suggestion) {
        if (Utils.isNull(YAHOO_TARGET_UTL)) {
            console.log(LOG_PREFIX + " yahoo target url is not specified");
        }
        Network.fetchWithTimeout(SEARCH_COUNT_ANALYTICS_TARGET_URL.replace(QUERY_MACRO, suggestion));
        window.open(YAHOO_TARGET_UTL + suggestion, '_blank');
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion}</span>
        )
    }

    render() {
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search',
            value: (Utils.isNull(this.lastQuery) ? '' : this.lastQuery),
            onKeyDown: this.onKeyDown,
            onChange: this.onChange,
        };
        console.log("last query" + this.lastQuery + "  " + inputProps.value);
        return (
            <div>
                <Grid item xs={12} className="inapp-main-wrapper">
                    <div className="main-browser-image" style={{ display: this.state.shouldHideBrandImage ? 'none' : 'block' }}><img src="../images/browser-main-image.svg" alt='Home' /></div>
                    <div className="search-input-wrapper">
                        <Autosuggest
                            style={theme}
                            onSuggestionSelected={this.onSuggestionSelected}
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            renderSuggestion={this.renderSuggestion}
                            inputProps={inputProps}
                            getSuggestionValue={this.getSuggestionValue}
                        />
                        <img className="search-icon" src="../images/search.svg" alt='Search' />
                    </div>
                    <LinearProgress style={this.state.isSearchingForQuery ? { width: '100%', height: '2px' } : { display: 'none' }} />
                    <div className="climate-wrapper" style={this.state.isWeatherDataAvailable ? {} : { display: "none" }}>
                        <div className="align-left">
                            <div className="climate-icon">
                                <img className={`align-climate-icon ${this.state.weatherData['icon']}`} src={"../images/climate-icons-blue/" + this.state.weatherData['icon'] + ".svg"} alt={this.state.weatherData['icon']} />
                            </div>
                            <div className="align-climate-content">
                                <div className="align-climate">
                                    <div className="climate-temp">{Math.round(this.state.weatherData['temperature'])}&#8451; in</div>
                                    <div className="city-name">{this.city}</div>
                                </div>
                                <div className="climate-condition">{this.state.weatherData['summary']}</div>
                            </div>
                        </div>
                        <div className="align-right">
                            <div className="align-climate-content">
                                <div className="align-climate">
                                    <div className="climate-temp">{this.state.temperatures['maxTemp']}&#8451; / {this.state.temperatures['minTemp'] }&#8451; </div>
                                </div>
                                <div className="climate-condition"><span className="weather-icon"><img src="../images/weather-icon-right.svg" alt="" /></span> 60% today</div>
                            </div>
                        </div>
                    </div>

                    <News></News>
                </Grid>
            </div>
        )
    }
}


export default Home