import Utils from './utils'

const DEFAULT_NETWORK_TIMEOUT = 20000;//No Kidding

class Network {
    fetchWithTimeout(url, timeout) {
        // Add default timeout
        if (Utils.isNull(timeout)) {
            timeout = DEFAULT_NETWORK_TIMEOUT;
        }

        return new Promise(function (resolve, reject) {
            var interval = setTimeout(function () {
                clearTimeout(interval);
                reject({ statusCode: 504, status: 'timeout - client' });
            }, timeout);
            fetch(url).then(function (response) {
                clearTimeout(interval);
                resolve(response);
            }).catch(function (error) {
                clearTimeout(interval);
                reject(error);
            });
        });
    }

    fetchWithTimeoutAndRetry(url, timeout, retryLimit) {
        return new Promise(async (resolve, reject) => {
            if (Utils.isNull(retryLimit) || retryLimit === 0) {
                retryLimit = 1
            }

            let currTry = 0
            let lastErr = null
            while (currTry < retryLimit) {
                try {
                    let resp = await this.fetchWithTimeout(url, timeout)
                    resolve(resp)
                    return

                } catch (err) {
                    console.log("Retrying again - ", currTry, err)
                    lastErr = err
                    currTry += 1
                }
            }

            if (Utils.isNull(lastErr)) {
                lastErr = new Error("Retries exceeded")
            }
            reject(lastErr)
        });
    }
}

let instance = new Network()
export default instance