import Network from '../../common/network'
import React from 'react';
import Utils from '../../common/utils';
import ClampLines from 'react-clamp-lines';
import { ListItemText, Card, CardMedia, CardContent } from '@material-ui/core';
import List from '@material-ui/core/List';
import theme from '../styles/home.css'
import LazyLoad from 'react-lazyload';



const LOG_PREFIX = '##news'

const NEWS_BASE_URL = 'https://hapi.newsprompt.co/HomePageExtension/getApiArticles?sec=usnews&maxno=100&ldesc=128'


export default class News extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newsItems: []
        };
    }

    componentDidMount() {
        this.fetchNewsItems()
    }

    parseNewsItems(resultSet) {
        var newsItems = []
        resultSet.forEach(element => {
            newsItems.push(new NewsItem(element));
        })
        return newsItems;
    }

    fetchNewsItems() {
        var newsItem=localStorage.getItem('news_item');
        var lastFetchTimestamp=localStorage.getItem('news_last_fetch_timestamp');
        if(Utils.isNull(newsItem)===false && this.hasNewsFetchThresholdPassed(lastFetchTimestamp)===false){
            this.setState({ newsItems: JSON.parse(newsItem) });
            return;
        }
        Network.fetchWithTimeout(NEWS_BASE_URL)
            .then((response) => {
                if (false === response.ok) {
                    throw Error(response.statusText)
                }
                return response.json()
            })
            .then((resp) => {
                let results = resp['resultSet']
                if (Utils.isNull(results)) {
                    throw new Error("Did not get success as true")
                }

                let formattedData = this.parseNewsItems(results)
                localStorage.setItem('news_item',JSON.stringify(formattedData));
                localStorage.setItem('news_last_fetch_timestamp',Date.now());
                this.setState({ newsItems: formattedData });
            })
            .catch((e) => {
                console.log(LOG_PREFIX, e);
            })
    }

    hasNewsFetchThresholdPassed(lastFetchedTimestamp){
        return Date.now()-lastFetchedTimestamp>1*60*60*1000
    }

    onNewsItemClicked(news) {
        window.open(news.target, '_blank');
    }


    render() {
        return (
            <div>
                <List component="nav">
                    {this.state.newsItems.map((news => {
                        return (
                            <LazyLoad height={200} key={news.target}>
                            <Card className="news-components-wrapper" onClick={() => this.onNewsItemClicked(news)} key={news.target} >
                                <CardContent className="news-components-blocks">
                                    <div className="image-wrapper">
                                        <CardMedia><div className="news-image"><img src={news.imageUrl} alt={"Media"}></img></div></CardMedia>
                                    </div>
                                    <div className="content-wrapper">

                                        <ClampLines
                                            text={news.text}
                                            lines={2}
                                            ellipsis="..."
                                            className="title"
                                            buttons={false}
                                        />

                                        <ClampLines
                                            text={news.description}
                                            lines={2}
                                            ellipsis="..."
                                            className="sub-title"
                                            buttons={false}
                                        />
                                        <div className="inline-wrap">
                                            <ListItemText className="site" primary={news.site}></ListItemText>
                                            <ListItemText className="category" primary={news.category}></ListItemText>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                            </LazyLoad>
                        )
                    }))}
                </List>
            </div>
        );
    }
}

class NewsItem {
    constructor(data) {
        this.target = data['u'];
        this.text = data['t'];
        this.description = data['desc'];
        this.imageUrl = data['imgr'];
        this.site = data['site'];
        this.category = data['ccategory'];
        //TODO add more
    }
}