export default class Utils {

    static getValForKeyFromCurrentUrl(key) {
        return this.getParameterByName(key)
    }

    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    static convertDecimalToPercentageString(val) {
        if (typeof (val) === "undefined" || val === null) {
            val = 0;
        } else if (typeof (val) === "string") {
            val = parseFloat(val)
            if (true === isNaN(val)) {
                val = 0;
            }
        }
        return '' + Math.round(val * 100)
    }

    static isNull(obj) {
        return (typeof (obj) === "undefined" || obj === null)
    }

    static isEmptyStr(obj) {
        return (this.isNull(obj) || (typeof obj === "string" && obj.trim() === ""));
    }


    // Copied from here - https://stackoverflow.com/a/2117523/1518924
    static generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

} 