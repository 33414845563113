import React from 'react';
import './App.css';
import Home from './components/home'
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

class App extends React.Component {
  render() {
      return (
          <BrowserRouter>
          <Switch>
              <Route path='/v1/home' component={Home} />
              {/* Defaults to redirecting to the main weather page */}
              <Route render={() => (
                  <Redirect to="/v1/home" />
              )} />
          </Switch>
          </BrowserRouter>
      )
  }
}

export default App;
